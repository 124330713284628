<!--
 * @Description: 评分标准配置
 * @Author: ChenXueLin
 * @Date: 2021-09-22 13:49:36
 * @LastEditTime: 2021-12-28 18:07:28
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div class="content-view section-wrapper">
      <div class="top-box">
        <el-input
          placeholder="请输入内容"
          v-model="searchValue"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
        <el-button type="primary" @click="handleAdd(1)">添加同级节点</el-button>
        <el-button type="primary" @click="handleAdd(2)">添加子级节点</el-button>
        <el-button type="primary" @click="handleEdit">编辑</el-button>
        <el-button type="primary" @click="handleDelete">删除</el-button>
      </div>
      <div class="tree-data">
        <e6-tree
          @func="handleTreeClick"
          v-model="selected"
          :list="treeData"
          :is-check="false"
          :is-open="true"
          issingleleaf
          ref="e6tree"
          :selectAndCheck="true"
        >
        </e6-tree>
      </div>
    </div>
    <el-dialog
      v-dialogDrag
      :title="
        dialogType == 1
          ? '添加同级'
          : dialogType == 2
          ? '添加子节点'
          : '编辑节点'
      "
      :visible.sync="addVisible"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      custom-class="createDialog"
    >
      <el-form
        ref="addForm"
        label-width="100px"
        :model="addForm"
        :rules="addFormRules"
      >
        <el-form-item
          :label="dialogType == 1 ? '节点名称' : '子节点名称'"
          prop="configValue"
        >
          <el-input
            v-model.trim="addForm.configValue"
            :placeholder="dialogType == 1 ? '节点名称' : '子节点名称'"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属上级" v-if="dialogType == 2">
          {{ treeInfo.label }}
        </el-form-item>
      </el-form>

      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getInspection, createConfig, deleteConfig, updateConfig } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "scoringStandardConfig",
  components: {},
  data() {
    return {
      treeInfo: {}, //当前点击的树节点
      searchValue: "",
      selected: [],
      treeData: [],
      /****新增*****/
      dialogType: 1, //1添加同级2添加子级
      addVisible: false,
      addForm: {
        configValue: "",
        parentId: ""
      },
      addFormRules: {
        configValue: [
          {
            required: true,
            message: "请输入节点名称",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  created() {
    this.getInspectionReq();
  },
  methods: {
    //查询质检配置列表
    async getInspectionReq() {
      try {
        this.loading = true;
        let res = await getInspection();
        this.handleTreeData(res.data);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        data[i]["label"] = data[i].configValue;
        data[i].pid = data[i].parentId ? data[i].parentId : 0;
        data[i].id = data[i].configId;
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.treeData = data;
    },
    // 处理树节点点击
    handleTreeClick(node) {
      this.treeInfo = node;
    },
    //模糊查询
    mapTree(value, arr) {
      let newarr = [];
      arr.forEach(element => {
        if (element.configValue.indexOf(value) > -1) {
          // 判断条件
          newarr.push(element);
        } else {
          if (element.children && element.children.length > 0) {
            let redata = this.mapTree(value, element.children);
            if (redata && redata.length > 0) {
              let obj = {
                ...element,
                children: redata
              };
              newarr.push(obj);
            }
          }
        }
      });
      return newarr;
    },
    handleSearch() {
      this.$refs.e6tree.queryTreeNodeByName(
        this.searchValue,
        false,
        false,
        true,
        true
      );
    },
    //添加
    handleAdd(type) {
      this.dialogType = type;
      if (this.dialogType == 2 && !this.treeInfo.id) {
        this.$message.warning("请先选择节点");
        return;
      }

      this.addVisible = true;
    },
    confirm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.dialogType == 1) {
            //添加同级
            this.addForm.parentId = this.treeInfo.parentId;
          }
          if (this.dialogType == 2) {
            //添加子节点
            this.addForm.parentId = this.treeInfo.id;
          }
          if (this.dialogType == 1 || this.dialogType == 2) {
            this.createConfigReq();
          } else {
            this.updateConfigReq();
          }
        }
      });
    },
    //新增请求
    async createConfigReq() {
      try {
        this.loading = true;
        let res = await createConfig(this.addForm);
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.getInspectionReq();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //编辑
    handleEdit() {
      if (!this.treeInfo.id) {
        this.$message.warning("请先选择节点");
        return;
      }
      //dialogType:3是编辑父节点4是编辑子节点
      this.dialogType = this.treeInfo.pid ? 4 : 3;
      this.addVisible = true;
      this.addForm.configValue = _.cloneDeep(this.treeInfo.configValue);
      // this.addForm.parentId = _.cloneDeep(this.treeInfo.parentId);
    },
    //编辑请求
    async updateConfigReq() {
      try {
        this.loading = true;
        let res = await updateConfig({ ...this.treeInfo, ...this.addForm });
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.getInspectionReq();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleDelete() {
      if (!this.treeInfo.id) {
        this.$message.warning("请先选择节点");
        return;
      }
      this.$confirm("是否确认删除？", "删除节点", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteConfigReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除请求
    async deleteConfigReq() {
      try {
        this.loading = true;
        let res = await deleteConfig({
          id: this.treeInfo.id,
          versionNumber: this.treeInfo.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.treeInfo = {};
          this.getInspectionReq();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.addForm = {
        configValue: "",
        parentId: ""
      };
      this.$refs.addForm.resetFields();
      this.treeInfo = {};
      this.addVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.top-box {
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #ebeef5;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .input-with-select {
    width: 240px;
    height: 35px;
    margin-right: 15px;
    /deep/.el-input__inner {
      height: 35px;
      line-height: 35px;
    }
  }
}
.tree-data {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: calc(100% - 55px);
}
/deep/.createDialog {
  .el-dialog__body {
    padding-top: 20px;
  }
  .el-input {
    width: 260px;
  }
}
</style>
